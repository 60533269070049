.resumeDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
}

.resumeDiv button {
  color: black;
  background-color: #eddfb3;
  padding: 20%;
}

.resumeDiv button:hover {
  background-color: brown;
  color: beige;
}

.resumeTitle {
  display: flex;
  justify-content: center;
  margin: 1%;
  font-size: 3rem;
  font-style: italic;
  min-height: 1.5em;
}

.contactMe{
  display: flex;
  justify-content: center;
  margin: 1%;
  margin-top: 10%;
  font-size: 3rem;
  font-style: italic;
  min-height: 1.5em;
}

.email{
  color: brown;
}

@media (max-width: 1000px) {
  .resumeTitle {
    margin: 3%;
  }

  .contactMe{
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .resumeTitle {
    margin: 4%;
    font-size: 2rem;
  }

  .contactMe{
    font-size: 1.2rem;
  }

  .email{
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .resumeTitle {
    margin: 6%;
  }

  .contactMe{
    font-size: 1.2rem;
  }

  .email{
    font-size: 1.5rem;
  }
}

/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}
