@keyframes alpha-transition {
  0% {
    --gradient-alpha: 1;
  }
  10% {
    --gradient-alpha: 0.9;
  }
  20% {
    --gradient-alpha: 0.8;
  }
  30% {
    --gradient-alpha: 0.7;
  }
  40% {
    --gradient-alpha: 0.6;
  }
  50% {
    --gradient-alpha: 0.5;
  }
  60% {
    --gradient-alpha: 0.4;
  }
  70% {
    --gradient-alpha: 0.3;
  }
  80% {
    --gradient-alpha: 0.2;
  }
  90% {
    --gradient-alpha: 0.1;
  }
  100% {
    --gradient-alpha: 0;
  }
}

.container {
  width: 100%;
}

.container-title {
  display: flex;
  height: 6em;
  align-items: center;
}

.container .card {
  background-blend-mode: saturation;
  --gradient-alpha: 1;
  --image-filter-layer: linear-gradient(
    rgba(0, 0, 0, var(--gradient-alpha)),
    rgba(0, 0, 0, var(--gradient-alpha))
  );
}

.container .card:hover {
  --gradient-alpha: 0;
  animation: alpha-transition 2s;
}

/* .container .card#project1 {
  background-image: var(--image-filter-layer), url("../images/projects/sql.png");
} */

.container .card#project1 {
  background-image: var(--image-filter-layer), url("../images/projects/chamisa.png");
}

.container .card#project2 {
  background-image: var(--image-filter-layer),
    url("../images/projects/test.png");
}

.container .card#project3 {
  background-image: var(--image-filter-layer),
    url("../images/projects/marketplace.jpg");
}

.container .card#project4 {
  background-image: var(--image-filter-layer),
    url("../images/projects/recipes.jpg");
}

.container .card#project5 {
  background-image: var(--image-filter-layer),
    url("../images/projects/wetranslate.png");
}

.container .card .card__head {
  color: #eddfb3;
  background: brown;
}

.container .card .p2,
.p3 {
  display: none;
}

.container .card:hover .p1 {
  color: black;
}

.container .card .p2 {
  color: rgba(141, 83, 11, 1);
}

.container .card .p3 {
  color: brown;
}

.container .card:hover .card__head {
  background: rgba(237, 223, 179, 0.9);
}

.projectsTitle {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-style: italic;
  min-height: 1.5em;
}

.projectLinks {
  display: flex;
  justify-content: center;
}

.projectLinks .p2{
  margin-left: 10px;
  margin-right: 10px;
}

/* ---------------------------------------------------------------------desktop---------------------------------------------------------------- */

@media (min-width: 1001px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6em;
    overflow: hidden;
    transform: skew(5deg);
  }

  .container .card {
    flex: 1;
    transition: all 1s ease-in-out;
    height: 75vmin;
    min-width: 45px;
    max-height: 42em;
    position: relative;
    background-size: cover;
    background-position: center;
  }

  .container .card .card__head {
    padding: 0.5em;
    transform: rotate(-90deg);
    transform-origin: 0% 0%;
    transition: all 0.5s ease-in-out;
    width: 250px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1em;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .container .card:hover .p1 {
    font-size: 2em;
  }

  .container .card .p2 {
    font-size: 1.5em;
  }

  .container .card .p3 {
    font-size: 1.2em;
  }

  .container .card:hover {
    flex-grow: 20;
    background-position: top;
  }

  .container .card:hover .card__head {
    text-align: center;
    top: calc(90% - 6em);
    transform: skew(0deg);
    width: calc(100% - 1em);
    overflow: hidden;
    font-weight: 500;
  }

  .container .card:hover .p2,
  .container .card:hover .p3 {
    white-space: pre-line;
  }

  .container .card:hover .p1,
  .container .card:hover .p2,
  .container .card:hover .p3 {
    transform: rotate(0deg) skew(-10deg);
    display: flex;
  }

  .container .card:not(:nth-child(5)) {
    margin-right: 1em;
  }
}

/* ------------------------------------------------------laptop----------------------------------------------- */

@media (min-width: 769px) and (max-width: 1000px) {
  .container {
    width: 90%;
    height: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transform: skew(5deg);
  }

  .container .card {
    flex: 1;
    height: 100px;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    transition: all 1s ease-in-out;
  }

  .container .card:hover {
    flex-grow: 5;
    justify-content: center;
    align-items: flex-end;
  }

  .container .card .card__head {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    text-align: center;
    transform: skew(-5deg);
  }

  .container .card:hover .card__head {
    transform: skew(0deg);
    flex-grow: 5;
    transition: 2s;
    font-weight: 500;
  }

  .container .card:hover .p1 {
    font-size: 1.2em;
  }

  .container .card .p2 {
    font-size: 0.9em;
  }

  .container .card .p3 {
    font-size: 0.9em;
  }

  .container .card:hover .p1,
  .p2,
  .p3 {
    transform: rotate(0deg) skew(-10deg);
  }

  .container .card:hover .p2,
  .container .card:hover .p3 {
    display: block;
  }

  .projectLinks {
    display: flex;
  }
}

/* ----------------------------------------------------------------tablet--------------------------------------------------- */

@media (min-width: 481px) and (max-width: 768px) {
  .container {
    width: 90%;
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transform: skew(5deg);
  }

  .container .card {
    flex: 1;
    height: 100px;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    transition: all 1s ease-in-out;
  }

  .container .card:hover {
    flex-grow: 5;
    justify-content: center;
    align-items: flex-end;
  }

  .container .card .card__head {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    text-align: center;
    transform: skew(-5deg);
  }

  .container .card:hover .card__head {
    transform: skew(0deg);
    flex-grow: 5;
    transition: 2s;
    font-weight: 500;
  }

  .container .card:hover .p1 {
    font-size: 1.2em;
  }

  .container .card .p2 {
    font-size: 0.9em;
  }

  .container .card .p3 {
    font-size: 0.7em;
  }

  .container .card:hover .p1,
  .p2,
  .p3 {
    transform: rotate(0deg) skew(-10deg);
  }

  .container .card:hover .p2,
  .container .card:hover .p3 {
    display: block;
  }

  .projectsTitle {
    font-size: 2rem;
  }
}

/* ------------------------------------------------------mobile----------------------------------------------- */

@media (max-width: 480px) {
  .container {
    width: 90%;
    height: 500px;
    display: flex;
    margin-bottom: 6em;
    justify-content: center;
    flex-direction: column;
    transform: skew(5deg);
  }

  .container .card {
    flex: 1;
    height: 100px;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    transition: all 1s ease-in-out;
  }

  .container .card:hover {
    flex-grow: 5;
    justify-content: center;
    align-items: flex-end;
  }

  .container .card .card__head {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    text-align: center;
    transform: skew(-5deg);
  }

  .container .card:hover .card__head {
    text-align: center;
    transform: rotate(0deg) skew(0deg);
    flex-grow: 5;
    transition: 2s;
    font-weight: 500;
  }

  .container .card:hover .p1 {
    font-size: 1.2em;
  }

  .container .card .p2 {
    font-size: 0.9em;
  }

  .container .card .p3 {
    font-size: 0.7em;
  }

  .container .card:hover .p1,
  .p2,
  .p3 {
    transform: rotate(0deg) skew(-10deg);
  }

  .container .card:hover .p2,
  .container .card:hover .p3 {
    display: block;
  }

  .projectsTitle {
    font-size: 2rem;
  }
}
