footer {
  height: 41px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eddfb3;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-left {
  height: 100%;
}

.footer-left {
  display: flex;
  width: 400px;
}

.footer-left a {
  text-decoration: none;
  color: inherit;
}

.git {
  margin-right: 5px;
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer-right a {
  text-decoration: none;
  color: inherit;
}

.footer-right a:hover {
  font-weight: bold;
}

/* ------------------------------------------laptop view------------------------------------------- */

@media (max-width: 1000px) {
  footer {
    height: 40px;
    padding: 20px;
  }

  .footer-left {
    width: 300px;
  }
}

/* ------------------------------------------tablet view------------------------------------------- */

@media (max-width: 768px) {
  footer {
    flex-direction: column-reverse;
  }

  .footer-left {
    justify-content: center;
    margin-top: 5px;
  }
}

/* ------------------------------------------phone view(210px min)------------------------------------------- */

@media (max-width: 480px) {
  .footer-right {
    display: none;
  }

  .footer-left {
    width: 180px;
    margin-top: 0;
  }
}
