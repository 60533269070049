html {
  position: relative;
  min-height: 100%;
}

.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcomeText {
  font-size: 3rem;
  font-style: italic;
  min-height: 1.5em;
  margin: 1%;
}

.aboutMeText {
  text-align: center;
  background-color: white;
  width: 20rem;
  padding: 20px;
  z-index: 2;
  border-radius: 10px;
  transform: translate(0, -15rem);
}

.ProfilePicture1 {
  width: 25rem;
  border-radius: 10px;
  position: relative;
  transform: translate(-18rem, -30rem);
  margin-bottom: -24em;
}

.ProfilePicture2 {
  width: 25rem;
  border-radius: 10px;
  position: relative;
  transform: translate(18rem);
}

/* ------------------------------------------laptop view------------------------------------------- */

@media (max-width: 1000px) {
  .welcomeText {
    margin: 3%;
  }

  .aboutMeText {
    width: 15rem;
    transform: translate(0, -12rem);
  }

  .ProfilePicture1 {
    width: 18rem;
    transform: translate(-14rem, -24rem);
  }

  .ProfilePicture2 {
    width: 18rem;
    transform: translate(14rem);
  }
}

/* -----------------------------------------tablet view---------------------------------- */

@media (max-width: 768px) {
  .welcomeText {
    font-size: 2rem;
    margin: 4%;
  }

  .aboutMeText {
    width: 26rem;
    padding: 20px;
    font-size: 18px;
    transform: translate(0rem, -2rem);
  }

  .ProfilePicture1 {
    width: 20rem;
    transform: translate(0rem, -4rem);
    margin-bottom: 14%;
  }

  .ProfilePicture2 {
    width: 20rem;
    transform: translate(0rem, 0rem);
  }
}

/* -----------------------------------------phone view----------------------------------------- */

@media (max-width: 480px) {
  .welcomeText {
    margin: 6%;
  }

  .aboutMeText {
    width: 18rem;
    font-size: 1rem;
    top: 1rem;
    transform: translate(0rem, -2rem);
  }

  .ProfilePicture1 {
    width: 16rem;
    transform: translate(0rem, -4rem);
  }

  .ProfilePicture2 {
    width: 16rem;
    transform: translate(0rem, 0rem);
  }
}
