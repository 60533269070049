header {
  height: 21px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eddfb3;
}

.header-left {
  width: 400px;
}

.menuOption {
  width: 100px;
  text-align: center;
}

.hire{
  color: brown;
  font-weight: bold;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right a {
  text-decoration: none;
  color: inherit;
}

.header-right a:hover {
  font-weight: bold;
}

.small-screen {
  display: none;
}

.small-screen-menu {
  display: none;
}

/*------------------flyer----------------*/

.modal {
  position: relative;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
  background: #eddfb3;
}

#hireMe{
  color: brown;
}

.flyer{
  object-fit: scale-down;
  height: 36rem;
  width: 27.6rem;
}

dialog:-internal-dialog-in-top-layer::backdrop {
  background-color: black;
  opacity: 30%;
}

#openModal{
  color: brown;
  background-color: #eddfb3;
  padding: 10px;
  border-radius: 30%;
  font-weight: bold;
}

#openModal:hover{
  color: #eddfb3;
  background-color: brown;
}



/* https://www.digitalocean.com/community/tutorials/how-to-use-opacity-and-transparency-to-create-a-modal-in-css */

#closeModal{
  color: transparent;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  overflow: hidden;
  background-image:
    linear-gradient(
      to top right,
      transparent 46%,
      black 46%,
      black 54%,
      transparent 52%
    ),
    linear-gradient(
      to top left,
      transparent 46%,
      black 46%,
      black 54%,
      transparent 52%
    );
  background-color: #eddfb3;
  border: none;
  right: 0.3rem;
  top: 0.3rem;
  position: absolute;
}


/*------------------fancy hover effect----------------*/

#pillar {
  margin-left: 130px;
  width: 100px;
  transform: translateX(-130px);
  perspective: 1900px;
}

.wrap {
  display: flex;
  transform: rotateY(0deg);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform-style: preserve-3d;
}

.row {
  width: 400px;
  position: relative;
  z-index: 2;
  color: black;
  font-size: large;
  font-weight: bold;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  transition: all 3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.row:hover .wrap {
  transform: rotateY(-45deg);
  cursor: pointer;
}

.row:hover {
  z-index: 1;
}

.row:hover::before .left {
  width: 55%;
}

.left {
  width: 350px;
  margin-top: 10px;
  align-items: center;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
}

.right {
  width: 350px;
  text-align: left;
  transform: rotateY(90deg);
  transform-origin: left center;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

/* ------------------------------------------laptop view------------------------------------------- */

@media (max-width: 1000px) {
  .header-left {
    width: 300px;
  }

  .menuOption {
    width: 95px;
  }

  .row {
    width: 200px;
  }

  .left {
    margin-bottom: 12px;
  }

  .right {
    margin-top: 16px;
    font-size: 9px;
  }
}

/* ------------------------------------------tablet view------------------------------------------- */

@media (max-width: 768px) {
  header {
    height: 40px;
    flex-direction: column;
  }

  .header-left {
    font-size: large;
    width: 400px;
  }

  .left {
    display: none;
  }

  .row {
    width: 400px;
    margin-left: 100%;
  }

  .right {
    display: none;
  }

  .small-screen {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .row .right {
    margin-top: 16px;
  }

  .modal {
    width: 20rem;
    height: 26rem;
    padding: 2rem;
  }
  
  .flyer{
    height: 26rem;
  }
  
}

/* ------------------------------------------phone view------------------------------------------- */

@media (max-width: 480px) {
  header {
    display: flex;
    align-items: stretch;
  }

  .small-screen-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-left {
    display: none;
    width: 180px;
  }

  /* this is causing the modal to not display */
  .header-right {
    display: none;
  }

  #hireMe:hover{
    font-weight: bold;
  }

  .title {
    display: flex;
    font-weight: bold;
    font-size: large;
  }

  .burger {
    display: flex;
    align-items: center;
    position: relative;
  }

  .small-screen-menu #myLinks {
    display: none;
    position: absolute;
    top: 80px;
    left: 0px;
    width: 100%;
    background-color: brown;
    z-index: 3;
  }

  .small-screen-menu a {
    color: black;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }

  .small-screen-menu #myLinks a {
    color: #eddfb3;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
    text-align: center;
  }

  .small-screen-menu #myLinks a:hover {
    background-color: #eddfb3;
    color: brown;
  }

  .burger a.icon {
    display: flex;
    position: relative;
  }

  .burger a:hover {
    color: brown;
  }

  .modal {
    width: 18rem;
    height: 24rem;
  }
  
  .flyer{
    height: 24rem;
  }
}
